export const ROUTE_SETTINGS = '/settings'
export const ROUTE_APP_SETTINGS = `${ROUTE_SETTINGS}/app`
export const ROUTE_SETTINGS_MENU = `${ROUTE_SETTINGS}/menu`
export const ROUTE_SETTINGS_ZONES = `${ROUTE_SETTINGS}/zones`
export const ROUTE_SETTINGS_USER = `${ROUTE_SETTINGS}/user`
export const ROUTE_SETTINGS_APP = `${ROUTE_SETTINGS}/app-config`
export const ROUTE_SETTINGS_PRINT = `${ROUTE_SETTINGS}/print-config`
export const ROUTE_SETTINGS_KIOSK = `${ROUTE_SETTINGS}/kiosk-config`
export const ROUTE_SETTINGS_HOURS = `${ROUTE_SETTINGS}/businesshours`
export const ROUTE_SETTINGS_HOURS_ONLINE = `${ROUTE_SETTINGS_HOURS}/online`
export const ROUTE_SETTINGS_HOURS_PHYSICAL = `${ROUTE_SETTINGS_HOURS}/physical`
export const ROUTE_SETTINGS_HEADER = `${ROUTE_SETTINGS}/header-text`
